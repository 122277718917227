<template>
	<div>
		<div class='mr300 ml300'>
			<div class="header_box  mt60 pl30 pr30 pb45 mb64">
				<div class="fs46 fw-600 w384 text-center team_title" style="margin: 0 auto;">百问百答</div>
			</div>
			<el-collapse v-model="activeNames" @change="handleChange">
				<el-collapse-item :title="item.questionName" :name="i+1" v-for="(item,i) in list" :key="i">
					<div v-html="item.answer" class="ql-editor"></div>
				</el-collapse-item>
			</el-collapse>

			<div class="text-center mt78 mb123">
				<el-pagination background layout="prev, pager, next" :total="count" :current-page='query.pageNum'
					:page-size='query.pageSize' @current-change='changePage'>
				</el-pagination>
			</div>

			<!-- 联系我们 -->
			<div class="fs46 color0 fw-600 text-center">联系我们</div>
			<div class="fs18 color9D text-center mt40 mb30">如您对于我们的产品或服务，请您随时联系我。同时如果您对我们有任何宝贵意见请参考以下联系方式联系我们。期待与您的沟通。
			</div>
			<img src="@/assets/images/aboutUs/map2.png" class="w1000 h808 mb30" style="margin: 0 auto;display: block;">
			<!-- /*/联系我们 -->
		</div>
		<div class="h360 color0 fs18 flex row-between pl155 pr155" style="background: #F9F9F9;">
			<div class="flex col-center">
				<i class="el-icon-phone fs64 "></i>
				<div>
					<div >
						重庆：<span class="ml15">023-62627264</span>
					</div>
					<div class="mt10">
						北京：<span class="ml15">01067869821</span>
					</div>
					<div class="mt10">
						杭州：<span class="ml15">85238218</span>
					</div>
				</div>
			</div>
			<div class="flex col-center">
				<i class="el-icon-message fs64 "></i>
				<span class="ml15">info@e-rijiben.com</span>
			</div>
			<div class="flex col-center">
				<i class="el-icon-location fs64 "></i>
				<div>
					<div>
						重庆：<span class="ml15">重庆市经开区长生桥镇美林路16号A9号楼第6层</span>
					</div>
					<div class="mt10">
						杭州：<span class="ml15">杭州市拱墅区祥茂路166号华滋科欣·设计创意园2号楼1108</span>
					</div>
					<div class="mt10">
						北京：<span class="ml15">北京市北京经济技术开发区路东区科创十三街锋创科技园8号楼401</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getQa } from '@/api'
	export default {
		data() {
			return {
				activeNames: ['1'],
				query: {
					pageNum: 1,
					pageSize: 5
				},
				count: null,
				list: []
			};
		},

		components: {},

		created() {
			this.$store.commit('editAboutRouterName', this.$route.meta.title)
			this.getQa()
		},

		mounted() {},

		methods: {
			//获取问答列表
			async getQa() {
				const res = await getQa(this.query)
				this.count = res.data.count
				this.list = res.data.obj
			},
			handleChange(val) {
				// console.log(val);
			},
			changePage(newPage) {
				this.activeNames=['1'],
				this.query.pageNum=newPage
				this.getQa()
			}
		}
	}
</script>
<style lang='scss' scoped>
	.header_box {
		border: 4px solid #f7b500;
	}

	.team_title {
		background-color: #fff;
		margin-top: -32px !important;
	}

	::v-deep .el-collapse-item__header {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #141414;
	}

	::v-deep .el-collapse-item__wrap {
		margin-top: 25px;
		margin-bottom: 40px;
	}

	::v-deep .el-collapse-item__content {
		/* font-size: 32px; */
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #121212;
		padding: 25px;
		background: rgba(247, 181, 0, 0.14);
	}
</style>
